import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const CvPageTemplate = ({
  file,
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content
  return (
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <section className="section">
            <h1 className="title">{title}</h1>
            <hr />
            <PageContent className="content" content={content} />
            <a href={file.publicURL}>Download CV</a>
          </section>
        </div>
      </div>
    </div>
  )
};

CvPageTemplate.propTypes = {
  file: PropTypes.shape({
    propTypes: PropTypes.string,
  }),
  title: PropTypes.string,
}

const CvPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <CvPageTemplate
        title={frontmatter.title}
        file={frontmatter.file}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

CvPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CvPage

export const pageQuery = graphql`
  query CvPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "cv-page" } }) {
      html
      frontmatter {
        title
        file { 
          publicURL
        }
      }
    }
  }
`
